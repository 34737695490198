import { Fragment, FunctionComponent, useState } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { SearchComponent } from 'components/UI';
import Header from '../Header/Header';
import { reduceResolution, wbp } from 'Theme';
import useNonInitialEffect from '@versiondos/hooks';
import Client from '@spike/client-model';
import { fetchClientsThunk, searchClientsThunk } from '@spike/clients-action';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { ClientsStatus } from '@spike/clients-action';
import { SmsMessage } from 'model/TwoWaySms';
import { TwoWaySmsStatus } from 'reducers/twowaysms/TwoWaySmsState';
import { useApiClientWrapper } from 'hooks';
import Filters from '../Search/Filters';
import { MessageFilterTypes } from '../Model';
import NewClientModal from '../NewClientModal/NewClientModal';
import SearchClientsResult from '../Search/SearchClientResult';
import SearchClientEmpty from '../Search/SearchClientEmpty';
import SearchChatsResult from '../Search/SearchChatsResult';
import Loader from '../ui/Loader';
import NewChatHeader from './NewChatHeader';
import { fetchTwoWaySmsCountThunk } from 'actions/twoWaySms/twoWaySmsActions';

interface SelectionMenuProps {
    chatActive?: boolean;
    clientSelected?: number;
    chatSearchActive?: boolean;
    clients?: Array<Client>;
    onGoBack: (chatSearchActive?: boolean) => void;
    onNewChatRequest: (chatSearchActive?: boolean) => void;
    onOpenChat: (chatActive: boolean, clientSelected: number, chatSearchActive?: boolean) => void;
    onChange?: (clients: Array<object>) => void;
    onSearchResult: () => void;
    onClearSelectedClient: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        },
        sideBarContainer: {
            display: 'flex',
            borderRadius: 20,
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            backgroundColor: '#FFF',
            border: '1px solid #D4D4D4'
        },
        fullWith: {
            width: '100%'
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            height: '12%',
            width: '100%',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(13)}px ${reduceResolution(13)}px ${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '13px 13px 15px'
            },
            backgroundColor: '#F1F1F1',
            borderBottom: '1px solid #D4D4D4'
        },
        newChatContainer: {
            width: '100%'
        },
        newChatContent: {
            display: 'flex',
            alignItems: 'center',
            height: '90%'
        },
        headerNewChatContainer: {
            width: '100%'
        },
        headerNewChat: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(13)}px ${reduceResolution(13)}px ${reduceResolution(15)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '13px 13px 15px'
            },
            color: '#FFF',
            backgroundColor: '#000',
            borderBottom: '1px solid #D4D4D4'
        },
        newChatTitle: {
            [theme.breakpoints.down(wbp)]: {
                fontSize: `${reduceResolution(20)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: '20px'
            },
            fontWeight: 600
        },
        searchContainer: {
            display: 'flex',
            width: '95%',
            margin: 0
        },
        searchClientContainer: {
            display: 'flex',
            width: '90%',
            marginLeft: 'auto',
            marginRight: 'auto',
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(15)}px`,
                marginBottom: `${reduceResolution(5)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '20px',
                marginBottom: '5px'
            }
        },
        search: {
            'height': 47,
            '& .MuiInputBase': {
                '&-root': {
                    'backgroundColor': '#fff',

                    '& svg': {
                        width: 16,
                        height: 16,
                        color: '#7A7A7A'
                    }
                },
                '&-input': {
                    fontSize: 14
                }
            }
        },

        menuContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '10%',
            textAlign: 'center'
        },
        goBackIcon: {
            cursor: 'pointer'
        },
        clientContainer: {
            flex: 1,
            [theme.breakpoints.down(wbp)]: {
                fontSize: reduceResolution(15)
            },
            [theme.breakpoints.up(wbp)]: {
                fontSize: 15
            },
            lineHeight: 1.4,
            display: 'flex',
            textAlign: 'start',
            flexDirection: 'column',
            justifyContent: 'start',
            height: '100%'
        },
        clientList: {
            height: '90%'
        },
        clientItemContainer: {
            [theme.breakpoints.down(wbp)]: {
                padddingTop: `${reduceResolution(12)}px`,
                padddingBottom: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingTop: '12px',
                paddingBottom: '12px'
            },
            'cursor': 'pointer',
            '&:hover': {
                backgroundColor: '#FAFBFA'
            }
        },
        clientAvatar: {
            backgroundColor: '#DCCCBB',
            [theme.breakpoints.down(wbp)]: {
                marginRight: `${reduceResolution(15)}px`,
                width: '25px',
                height: '25px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '15px'
            }
        },
        clientText: {
            fontWeight: 600
        },
        clientDivider: {
            backgroundColor: '#F1F1F1',
            margin: 'auto',
            width: '90%'
        },
        chatsContainer: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column'
        },
        chatContainer: {
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(25)}px`,
                marginBottom: `${reduceResolution(10)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '25px',
                marginBottom: '10px'
            },

            '&:hover': {
                backgroundColor: '#F8F5F1'
            }
        },

        noClientContainer: {
            flex: 1,
            fontSize: 15,
            lineHeight: 1.4,
            [theme.breakpoints.down(wbp)]: {
                paddding: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '40px'
            },
            display: 'flex',
            textAlign: 'center',
            flexDirection: 'column',
            justifyContent: 'center'
        },
        noClientIcon: {
            margin: '0px auto 15px'
        },
        noClientTitle: {
            fontWeight: 600,
            fontSize: '26px !important'
        },
        noClientParagraph: {
            fontSize: 15,
            lineHeight: 1.4,
            marginBottom: 20
        },
        newClientButton: {
            width: 'auto',
            fontWeight: 500,
            paddingLeft: '35px',
            paddingRight: '35px',
            margin: '0px auto',
            display: 'inline-block'
        },
        newClientButtonInList: {
            width: 'auto',
            fontWeight: 500,
            [theme.breakpoints.down(wbp)]: {
                paddingLeft: `${reduceResolution(35)}px`,
                paddingRight: `${reduceResolution(35)}px`,
                marginTop: `${reduceResolution(12)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                paddingLeft: '35px',
                paddingRight: '35px',
                marginTop: '12px'
            },
            display: 'flex',
            justifyContent: 'right'
        },
        newClientDropdownContainer: {
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 16px'
        },

        wrapper: {
            width: '100%',
            height: '100%',
            overflow: 'hidden'
        },
        loadingText: {
            fontSize: '8px',
            textAlign: 'center'
        },
        clientScroll: {
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(15)}px ${reduceResolution(15)}px ${reduceResolution(
                    15
                )}px ${reduceResolution(15)}px`,
                maxHeight: reduceResolution(540),
                minHeight: `${reduceResolution(540)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '15px 15px 15px 15px',
                maxHeight: 540,
                minHeight: '540px'
            }
        },
        chatScroll: {
            width: '100%',
            height: '100%',
            overflowY: 'scroll',
            overflowX: 'hidden',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(15)}px ${reduceResolution(15)}px ${reduceResolution(
                    15
                )}px ${reduceResolution(15)}px`,
                maxHeight: reduceResolution(600),
                minHeight: `${reduceResolution(590)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '15px 15px 15px 15px',
                maxHeight: 600,
                minHeight: '590px'
            }
        }
    })
);

const SelectionMenu: FunctionComponent<SelectionMenuProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    const clients = useSelector<RootState, Array<Client>>(state => state.clients.list);

    const clientsStatus = useSelector<RootState, ClientsStatus>(state => state.clients.status);
    const twoWaySmsStatus = useSelector<RootState, TwoWaySmsStatus>(state => state.twoWaySms.status);

    const actualConversation = useSelector<RootState, Array<SmsMessage>>(state => state.twoWaySms.conversation ?? []);

    const [newClientIsActive, setNewClientIsActive] = useState(false);
    const [newClientIsBeingSelected, setNewClientIsBeingSelected] = useState(false);
    const [clientSearchText, setClientSearchText] = useState('');
    const [chatSearchText, setChatSearchText] = useState('');
    const [clientSelected, setClientSelected] = useState<number | undefined>(undefined);
    const [selectedFilter, setSelectedFilter] = useState<string>(MessageFilterTypes.NEWEST);
    const [chatSearchActive, setChatSearchActive] = useState(props.chatSearchActive ?? true);

    const [loading, setLoading] = useState({
        clients: true,
        conversations: true,
        sendMessage: false
    });

    useNonInitialEffect(() => {
        props.onChange && props.onChange(clients);
    }, [clients]);

    useNonInitialEffect(() => {
        if (newClientIsBeingSelected) {
            if (actualConversation.length > 0) {
                setChatSearchActive(true);
            }
        }
        setNewClientIsBeingSelected(false);
    }, [actualConversation]);

    const handleGoBack = () => {
        setChatSearchActive(true);
        setClientSearchText('');
        props.onGoBack && props.onGoBack();
    };

    const handleNewChatRequest = () => {
        setLoading({ ...loading, clients: true });
        setChatSearchActive(false);
        fetchClients(1, 8);
        setClientSearchText('');
        props.onClearSelectedClient && props.onClearSelectedClient();
        props.onNewChatRequest && props.onNewChatRequest(false);
    };

    const selectClientHandler = (clientId?: number) => {
        setClientSearchText('');
        setLoading({ ...loading, clients: true, conversations: true });
        if (clientId) {
            setNewClientIsBeingSelected(true);
            setClientSelected(clientId);
            dispatch(fetchTwoWaySmsCountThunk());
            props.onOpenChat && props.onOpenChat(true, clientId, true);
        }
    };

    const handleNewClientRequest = () => {
        setNewClientIsActive(true);
    };

    const fetchClients = (page: number, pageSize: number) => {
        setLoading({ ...loading, clients: true });
        if (clientSearchText.length === 0) {
            dispatch(fetchClientsThunk(apiClientWrapper, page, pageSize));
        } else if (clientSearchText.length >= 3) {
            dispatch(searchClientsThunk(apiClientWrapper, clientSearchText, page, pageSize));
        }
    };

    const handleChatSearch = (text: string) => {
        setChatSearchText(text);
        setTimeout(() => fetchClients(1, 30), 300);
    };

    const handleClientSearch = (text: string) => {
        setClientSearchText(text);
    };

    useNonInitialEffect(() => {
        switch (twoWaySmsStatus) {
            case TwoWaySmsStatus.FetchSuccess:
                setLoading({ ...loading, conversations: false });
                break;
            default:
                break;
        }
    }, [twoWaySmsStatus]);

    useNonInitialEffect(() => {
        switch (clientsStatus) {
            case ClientsStatus.FetchSuccess:
                setLoading({ ...loading, clients: false });
                break;
            case ClientsStatus.GetSuccess:
                setLoading({ ...loading, clients: false });
                break;
            default:
                break;
        }
    }, [clientsStatus]);

    const clearHandler = () => {
        setClientSelected(undefined);
        setLoading({ ...loading, clients: true, conversations: true });
        props.onClearSelectedClient && props.onClearSelectedClient();
    };

    return (
        <Box className={classes.root}>
            {chatSearchActive === true && (
                <Header
                    chatSearchActive={chatSearchActive}
                    onNewChatRequest={() => handleNewChatRequest()}
                />
            )}
            <Box className={classes.sideBarContainer}>
                {chatSearchActive === true && (
                    <Box className={classes.wrapper}>
                        <Box className={classes.header}>
                            <Box className={classes.searchContainer}>
                                <SearchComponent
                                    open={true}
                                    className={classes.search}
                                    placeholder="Search by client name"
                                    onSearch={handleChatSearch}
                                />
                            </Box>
                            <Filters onSelect={option => setSelectedFilter(option)} />
                        </Box>

                        <SearchChatsResult
                            chatSearchText={chatSearchText}
                            selectedFilter={selectedFilter}
                            clientSelected={Number(clientSelected)}
                            onSelectClient={selectClientHandler}
                            onClearSelectedClient={clearHandler}
                        />
                    </Box>
                )}
                {chatSearchActive === false && (
                    <Box className={classes.newChatContainer}>
                        <NewChatHeader handleGoBack={handleGoBack} />
                        <Box className={classes.newChatContent}>
                            <Fragment>
                                <Box
                                    className={
                                        clients.length === 0 ? classes.noClientContainer : classes.clientContainer
                                    }
                                >
                                    {clients.length !== 0 && (
                                        <Box className={classes.searchClientContainer}>
                                            <SearchComponent
                                                open={true}
                                                className={classes.search}
                                                searchText={clientSearchText}
                                                placeholder="Name or phone numbers"
                                                onSearch={handleClientSearch}
                                            />
                                        </Box>
                                    )}
                                    {!loading.clients && clients.length !== 0 && (
                                        <SearchClientsResult
                                            clientSearchText={clientSearchText}
                                            onSelectClient={client => {
                                                selectClientHandler(client);
                                                setChatSearchActive(true);
                                            }}
                                            onAddClient={handleNewClientRequest}
                                        />
                                    )}

                                    {!loading.clients && clients.length === 0 && (
                                        <SearchClientEmpty onClientAdd={handleNewClientRequest} />
                                    )}

                                    {loading.clients && <Loader />}
                                </Box>
                            </Fragment>
                        </Box>
                    </Box>
                )}
            </Box>
            {newClientIsActive && (
                <NewClientModal
                    isOpen
                    onClose={() => setNewClientIsActive(false)}
                />
            )}
        </Box>
    );
};

export default SelectionMenu;
