import { FunctionComponent } from 'react';
import clsx from 'clsx';
import { Badge, Box, createStyles, Grid, makeStyles, Typography } from '@material-ui/core';
import moment from 'moment-timezone';
import { SmsChat } from 'model/TwoWaySms';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import useNonInitialEffect from '@versiondos/hooks';
import { TwoWaySmsStatus } from 'reducers/twowaysms/TwoWaySmsState';

interface SearchChatsResultItemProps {
    chat: SmsChat;
    active: boolean;
    onClick?: () => void;
}

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            'display': 'block',
            'cursor': 'pointer',
            'borderRadius': 10,
            'minHeight': '83px',
            'maxHeight': '83px',
            'margin': '10px',
            'padding': '12px 16px',
            'position': 'relative',
            'border': 'solid 1px transparent',
            'transition': 'background-color 0.1s ease-in, border-color 0.1s ease-in',
            '&:hover': {
                border: 'solid 1px #000',
                backgroundColor: '#F8F5F1'
            }
        },
        active: {
            border: 'solid 1px #000',
            backgroundColor: '#F8F5F1'
        },
        heading: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            marginBottom: 12
        },
        title: {
            color: '#222',
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 600,
            paddingRight: 6
        },
        titleUnread: {
            fontSize: 14,
            lineHeight: 1,
            fontWeight: 600,
            paddingRight: 6,
            color: '#5E8677 !important'
        },
        message: {
            fontSize: 13,
            lineHeight: 1.2,
            fontWeigth: 400,
            color: '#7A7A7A',
            paddingRight: 25
        },
        messageUnread: {
            fontSize: 13,
            lineHeight: 1.2,
            fontWeight: 500,
            color: '#000',
            paddingRight: 25
        },
        sent: {
            fontSize: 11,
            lineHeight: 1,
            fontWeight: 500,
            color: '#A2A2A5',
            whiteSpace: 'nowrap'
        },
        sentUnread: {
            fontSize: 11,
            lineHeight: 1,
            fontWeight: 500,
            color: '#5E8677',
            whiteSpace: 'nowrap'
        },
        unReadBadgeRoot: {
            display: 'inline !important'
        },
        customBadge: {
            whiteSpace: 'nowrap',
            lineHeight: 1,
            backgroundColor: '#5E8677',
            color: 'white',
            borderRadius: '10px',
            top: '5px',
            fontWeight: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center,'
        }
    })
);

const SearchChatsResultItem: FunctionComponent<SearchChatsResultItemProps> = ({ chat, onClick, active }) => {
    const classes = useStyles();
    const unReadMessagesCount = active ? 0 : chat.unread;
    const contentMessage = chat.message;

    const messagesStatus = useSelector<RootState, TwoWaySmsStatus>(state => state.twoWaySms.status);

    const generateContent = () => {
        return contentMessage.message.length < 27
            ? contentMessage.message
            : contentMessage.message.slice(0, 26) + '...';
    };

    const content = generateContent();

    useNonInitialEffect(() => {
        if (messagesStatus === TwoWaySmsStatus.SaveSuccess) {
            generateContent();
        } else if (messagesStatus === TwoWaySmsStatus.SetConversationsSuccess) {
            generateContent();
        }
    }, [messagesStatus]);

    return (
        <Box
            id={`chat-${chat.client.id}`}
            className={active === true ? clsx(classes.root, classes.active) : classes.root}
            onClick={onClick}
        >
            <Box className={classes.heading}>
                <Typography className={unReadMessagesCount > 0 ? classes.titleUnread : classes.title}>
                    {chat.client.name ?? ''}
                </Typography>
                <span className={unReadMessagesCount > 0 ? classes.sentUnread : classes.sent}>
                    {moment(chat.message.sentAt).fromNow()}
                </span>
            </Box>
            <Grid
                container
                justifyContent="flex-start"
            >
                <Grid
                    item
                    xs={11}
                >
                    <Typography className={unReadMessagesCount > 0 ? classes.messageUnread : classes.message}>
                        {content}{' '}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={1}
                >
                    {unReadMessagesCount > 0 && (
                        <Badge
                            classes={{
                                root: classes.unReadBadgeRoot,
                                badge: classes.customBadge
                            }}
                            badgeContent={unReadMessagesCount}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right'
                            }}
                        ></Badge>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default SearchChatsResultItem;
