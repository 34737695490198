import { faArrowLeft } from '@fortawesome/free-solid-svg-icons/faArrowLeft';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { MarketplaceStatus, changeOlbStatusThunk } from '@spike/marketplace-action';
import { MarketplaceLogos, MarketplaceOnlineBooking } from '@spike/marketplace-model';
import { ACCESS_LEVEL_ADMIN_ID } from '@spike/model';
import useNonInitialEffect from '@versiondos/hooks';
import { reduceResolution, wbp } from 'Theme';
import clsx from 'clsx';
import { Switch } from 'components/UI';
import { useApiClientWrapper, useAuth } from 'hooks';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { BusinessSettingsTitle } from '../UI';
import Profile from './Profile';
import Settings from './Settings';
import Tabs, { profileTabId, settingsTabId } from './Tabs';

export interface Props {
    onlineBooking: MarketplaceOnlineBooking;
    className?: string;
    openSidebar?: () => void;
    isMobile?: boolean;
    logos: MarketplaceLogos;
    onSave?: (logos: MarketplaceLogos) => void;
    onChange?: (logos: MarketplaceLogos) => void;
    onSaveMarketplace: (pricingOption: string) => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: 100,
            [theme.breakpoints.only('md')]: {
                width: '368px'
            },
            [theme.breakpoints.only('lg')]: {
                width: '658px'
            },
            [theme.breakpoints.only('xl')]: {
                width: '900px'
            },
            [theme.breakpoints.down('sm')]: {
                width: '100%'
            }
        },
        headerContainer: {
            display: 'flex',
            [theme.breakpoints.only('md')]: {
                marginBottom: '2px'
            },
            [theme.breakpoints.only('lg')]: {
                marginBottom: '5px'
            },
            [theme.breakpoints.only('xl')]: {
                marginBottom: '6.5px'
            },
            [theme.breakpoints.down('sm')]: {
                marginBottom: '5px'
            }
        },
        titleContainer: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between'
        },
        businessSettingsTittle: {
            display: 'flex',
            alignItems: 'center',
            [theme.breakpoints.down('sm')]: {
                marginBottom: '5px'
            }
        },
        iconBusinessTitle: {
            position: 'relative',
            marginRight: '8px'
        },
        row: {
            display: 'flex',
            width: '100%',
            [theme.breakpoints.only('md')]: {
                marginTop: '4px',
                marginBottom: '4px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '10px',
                marginBottom: '10px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '13px',
                marginBottom: '13px'
            },
            [theme.breakpoints.down('sm')]: {
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        rowInput: {
            [theme.breakpoints.down('sm')]: {
                '& input': {
                    height: '52px !important',
                    fontSize: '16px',
                    fontWeight: '400',
                    textOverflow: 'ellipsis !important',
                    paddingRight: '5px !important'
                },
                '& button': {
                    'height': '37px',
                    'width': '100px',
                    'paddingLeft': '16px',
                    'paddingRight': '15px',
                    '& .MuiButton-label': {
                        'fontSize': '14px',
                        'fontWeight': '600',
                        '& .MuiButton-startIcon': {
                            'marginRight': '7px',
                            '& svg': {
                                width: '14px',
                                height: '14px'
                            }
                        }
                    }
                },
                '& fieldset': {
                    borderColor: '#f00 !important'
                }
            }
        },
        switchContainer: {
            display: 'flex',
            flexDirection: 'column'
        },
        subtitle: {
            width: '100%',
            fontSize: '16px',
            lineHeight: '25px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '140%',
                marginBottom: '0px'
            }
        },
        alert: {
            color: '#000',
            fontWeight: 600,
            fontSize: '16px',
            [theme.breakpoints.down('sm')]: {
                fontSize: '6px',
                lineHeight: '14px'
            },
            [theme.breakpoints.only('md')]: {
                lineHeight: '21px'
            },
            [theme.breakpoints.only('lg')]: {
                lineHeight: '28px'
            },
            [theme.breakpoints.only('xl')]: {
                lineHeight: '42px'
            }
        },
        alertContainer: {
            width: '100%',
            display: 'flex',
            border: 'solid 2px #BCB8AE',
            backgroundColor: '#F8F5F1',
            [theme.breakpoints.down('sm')]: {
                borderRadius: 18,
                padding: 17,
                marginTop: '10px',
                marginBottom: '10px'
            },
            [theme.breakpoints.up('sm')]: {
                borderRadius: 20,
                padding: 15,
                marginTop: '10px',
                marginBottom: '10px'
            }
        },
        alertText: {
            color: '#000 !important',
            width: '100%',
            [theme.breakpoints.down('sm')]: {
                fontSize: '14px',
                lineHeight: '18px'
            },
            [theme.breakpoints.up('sm')]: {
                fontSize: '16px',
                lineHeight: '22px'
            }
        },
        icon: {
            color: '#BAA997',
            fontSize: '16px',
            [theme.breakpoints.down(wbp)]: {
                marginRight: '8px'
            },
            [theme.breakpoints.up(wbp)]: {
                marginRight: '8px',
                marginTop: '2px'
            }
        },
        sectionRow: {
            [theme.breakpoints.down(wbp)]: {
                marginTop: `${reduceResolution(22)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                marginTop: '22px'
            }
        }
    })
);

export const OnlineBooking: FunctionComponent<Props> = props => {
    const classes = useStyles();
    const auth = useAuth();

    const isAdmin = auth.user!.accessLevel.id === ACCESS_LEVEL_ADMIN_ID;

    const [enabled, setEnabled] = useState(props.onlineBooking.active);
    const [showSuccess, setShowSuccess] = useState(!!props.onlineBooking.slug);
    const [selectedTab, setSelectedTab] = useState<string>(profileTabId);

    const marketplaceStatus = useSelector<RootState, MarketplaceStatus>(state => state.marketplace.status);

    const dispatch = useDispatch();
    const apiClientWrapper = useApiClientWrapper();

    useNonInitialEffect(() => {
        if (marketplaceStatus === MarketplaceStatus.ChangeOlbStatusSuccess && enabled) {
            setShowSuccess(true);
        }
        if (marketplaceStatus === MarketplaceStatus.Error) {
            setEnabled(false);
        }
    }, [marketplaceStatus]);

    const changeEnableHandler = (checked: boolean) => {
        setEnabled(checked);
        dispatch(changeOlbStatusThunk(apiClientWrapper, checked));
    };

    const selectTabHandler = (tabId: string) => {
        setSelectedTab(tabId);
    };

    return (
        <Box className={clsx(classes.container, props.className)}>
            <Box className={classes.headerContainer}>
                <Box className={classes.titleContainer}>
                    <BusinessSettingsTitle className={classes.businessSettingsTittle}>
                        {' '}
                        {props.isMobile && (
                            <FontAwesomeIcon
                                className={classes.iconBusinessTitle}
                                onClick={props.openSidebar}
                                icon={faArrowLeft}
                                size="2x"
                            />
                        )}{' '}
                        Online Booking
                    </BusinessSettingsTitle>
                </Box>
                <Box className={classes.switchContainer}>
                    {isAdmin && (
                        <Switch
                            checked={enabled}
                            onChange={checked => changeEnableHandler(checked)}
                        ></Switch>
                    )}
                </Box>
            </Box>
            {enabled && showSuccess ? (
                <Box className={classes.alertContainer}>
                    <FontAwesomeIcon
                        icon={faCircleCheck}
                        className={classes.icon}
                    />
                    <Box>
                        <Typography className={classes.alertText}>
                            Your online booking is currently enabled, and you&apos;re ready to accept bookings from pet
                            parents.
                        </Typography>
                    </Box>
                </Box>
            ) : (
                <Box className={classes.alertContainer}>
                    <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={classes.icon}
                    />
                    <Box>
                        <Typography className={classes.alertText}>
                            Your online booking is currently disabled. Enable to allow pet parents to book appointments
                            online.
                        </Typography>
                    </Box>
                </Box>
            )}

            {enabled && (
                <>
                    <Tabs
                        tabId={selectedTab}
                        selectTabHandler={selectTabHandler}
                    />
                    {selectedTab === profileTabId && (
                        <Profile
                            onlineBooking={props.onlineBooking}
                            logos={props.logos}
                            onSave={props.onSave}
                            onChange={props.onChange}
                        />
                    )}
                    {selectedTab === settingsTabId && (
                        <Settings
                            onlineBooking={props.onlineBooking}
                            isAdmin={isAdmin}
                            isMobile={props.isMobile}
                            onSaveMarketplace={props.onSaveMarketplace}
                        />
                    )}
                </>
            )}
        </Box>
    );
};

export default OnlineBooking;
