import {
    StaffAction,
    STAFF_RESET_ACTION_TYPE,
    STAFF_FETCH_START_ACTION_TYPE,
    STAFF_FETCH_SUCCESS_ACTION_TYPE,
    STAFF_GET_START_ACTION_TYPE,
    STAFF_GET_SUCCESS_ACTION_TYPE,
    STAFF_GET_BY_EMAIL_START_ACTION_TYPE,
    STAFF_GET_BY_EMAIL_SUCCESS_ACTION_TYPE,
    STAFF_GET_BY_EMAIL_NOT_FOUND_ACTION_TYPE,
    STAFF_SAVE_START_ACTION_TYPE,
    STAFF_SAVE_SUCCESS_ACTION_TYPE,
    STAFF_SAVE_COMMISSIONS_START_ACTION_TYPE,
    STAFF_SAVE_COMMISSIONS_SUCCESS_ACTION_TYPE,
    STAFF_SAVE_DEFAULT_SCHEDULE_START_ACTION_TYPE,
    STAFF_SAVE_DEFAULT_SCHEDULE_SUCCESS_ACTION_TYPE,
    STAFF_DELETE_START_ACTION_TYPE,
    STAFF_DELETE_SUCCESS_ACTION_TYPE,
    STAFF_ACTIVATE_DEACTIVATE_START_ACTION_TYPE,
    STAFF_ACTIVATE_DEACTIVATE_SUCCESS_ACTION_TYPE,
    STAFF_ERROR_ACTION_TYPE,
    STAFF_RESEND_INVITATION_START_ACTION_TYPE,
    STAFF_RESEND_INVITATION_SUCCESS_ACTION_TYPE,
    STAFF_RESEND_INVITATION_ERROR_ACTION_TYPE,
    STAFF_SET_WARNINGS_ACTION_TYPE,
    STAFF_CLEAR_WARNINGS_ACTION_TYPE
} from './StaffActionsTypes';
import { ThunkAction } from 'redux-thunk';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { StaffState } from 'reducers/staff/StaffState';
import ApiClient, { createTokenConfig, createMultipartTokenConfig, isCancelled } from 'api/ApiClient';
import { NotificationsAction, showSuccess, showSuccessThunk, showWarningThunk } from '@spike/notifications-action';
import { showError } from '@spike/notifications-action';
import store, { RootState } from 'store';
import { convertStaff, convertEmergencyContactToDto } from './StaffConverter';
import { Staff, StaffSchedule, StaffCommission, StaffSlots, StaffDefaultSlot } from 'model/Staff';
import { StaffDto, DefaultSlotDto } from './StaffDto';
import { alertErrorHandler } from '@spike/notifications-action';
import { serialize } from 'object-to-formdata';
import { LoginAction } from 'actions/login/LoginActionsTypes';
import { changeAvatar } from 'actions/login/LoginActions';
import { Week } from '@spike/model';
import { removeCustomsEqualsAsDefault } from 'components/StaffSchedule/utils/StaffScheduleUtils';

const staffUrl = '/staffs';
const findByEmailUrl = '/staff/find';

export const fetchStaffThunk = (): ThunkAction<void, StaffState, null, StaffAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(fetchStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${staffUrl}?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<Array<StaffDto>> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(fetchSucces(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error fetching staff.'));
            }
        }
    };
};

export const getStaffThunk = (
    staffId: number
): ThunkAction<void, StaffState, null, StaffAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(getStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${staffUrl}/${staffId}?marketplace_id=${marketplaceId}`;

        try {
            const response: AxiosResponse<StaffDto> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            dispatch(getSucces(response.data));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error getting staff.'));
            }
        }
    };
};

export const getStaffByEmailThunk = (
    email: string
): ThunkAction<void, StaffState, null, StaffAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(getByEmailStart());
        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${findByEmailUrl}?marketplace_id=${marketplaceId}&email=${encodeURIComponent(email)}`;

        try {
            const response: AxiosResponse<StaffDto> = await ApiClient.get(
                url,
                createTokenConfig(store.getState().login.auth.token!)
            );
            if (response.data === null) {
                dispatch(getByEmailNotFound());
            } else {
                dispatch(getByEmailSucces(response.data));
            }
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                dispatch(showError('Error getting staff by email.'));
            }
        }
    };
};

export const saveStaffThunk = (
    staff: Staff
): ThunkAction<void, StaffState, null, StaffAction | LoginAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(saveStart());

        const marketplaceId = store.getState().login.auth.marketplaceId!;
        const apiClientMethod = staff.id ? ApiClient.patch : ApiClient.post;
        const url = staff.id
            ? `${staffUrl}/${staff.id}?marketplace_id=${marketplaceId}`
            : `${staffUrl}?marketplace_id=${marketplaceId}`;

        try {
            let member: Staff | undefined;

            if (staff.id) {
                member = store.getState().staff.member;

                if (!member) {
                    member = store.getState().staff.staff.find(s => s.id === staff.id);
                }

                if (!member) {
                    const getUrl = `${staffUrl}/${staff.id}?marketplace_id=${marketplaceId}`;
                    const response: AxiosResponse<StaffDto> = await ApiClient.get(
                        getUrl,
                        createTokenConfig(store.getState().login.auth.token!)
                    );
                    member = convertStaff(response.data);
                }
            }

            const staffRequest = {
                id: staff.id,
                uuid: staff.uuid,
                marketplace_id: marketplaceId,
                first_name: staff.person.firstName,
                last_name: staff.person.lastName,
                birthday: staff.person.birthdate ? staff.person.birthdate.format('YYYY-MM-DD') : '',
                address: staff.person.address,
                email: staff.person.email.trim().toLowerCase(),
                phone: staff.person.phoneNumber,
                email_notifications: staff.notifications.email,
                phone_notifications: staff.notifications.phone,
                performs_attributes: [
                    ...staff.services.map(service => ({
                        id: service.performId,
                        service_id: service.id
                    })),
                    ...(member?.services
                        .filter(
                            memberService => !staff.services.some(staffService => staffService.id === memberService.id)
                        )
                        .map(deletedService => ({
                            id: deletedService.performId,
                            service_id: deletedService.id,
                            _destroy: true
                        })) || [])
                ],
                business_area_list: [],
                emergency_contacts_attributes: staff.emergencyContact
                    ? convertEmergencyContactToDto(staff.emergencyContact)
                    : [],
                active: staff.active,
                works_attributes: convertWorksToDto(staff, marketplaceId, member)
            };

            const marketplaceWork = staffRequest.works_attributes.find(w => w.marketplace_id === marketplaceId);

            if (marketplaceWork) {
                marketplaceWork.role_id = staff.role?.id;
                marketplaceWork.access_level_id = staff.accessLevel?.id;
            } else {
                staffRequest.works_attributes.push({
                    id: undefined,
                    marketplace_id: marketplaceId,
                    staff_id: staff.id,
                    role_id: staff.role?.id,
                    access_level_id: staff.accessLevel?.id,
                    calendar_attributes: {
                        id: undefined,
                        marketplace_id: marketplaceId,
                        range: JSON.stringify(staff.schedule.default)
                    },
                    customize_schedules_attributes: staff.schedule.customDays.map(cd => ({
                        id: cd.id,
                        work_id: undefined,
                        uuid: cd.uuid,
                        from: cd.from.toDate(),
                        to: cd.to.toDate(),
                        on: cd.on
                    }))
                });
            }

            let formData = new FormData();

            const options = {
                indices: false,
                nullsAsUndefineds: false,
                booleansAsIntegers: false,
                allowEmptyArrays: false
            };

            formData = serialize(staffRequest, options, formData, 'staff');

            if (staff.services.length === 0) {
                formData.append('staff[service_ids][]', '');
            }

            if (staff.person.fileToUpload) {
                formData.append('staff[avatar]', staff.person.fileToUpload);
            }

            const response: AxiosResponse<StaffDto> = await apiClientMethod(
                url,
                formData,
                createMultipartTokenConfig(store.getState().login.auth.token!)
            );
            const savedStaff = convertStaff(response.data);
            dispatch(saveSuccess(savedStaff));
            changeAvatar(savedStaff.person.avatar, savedStaff.id!, dispatch);

            dispatch(fetchStaffThunk());
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                const name = `${staff.person.firstName} ${staff.person.lastName}`;
                alertErrorHandler(apiError, dispatch, `Error saving staff member ${name}.`);
            }
        }
    };
};

export const saveCommissionsThunk = (
    staffId: number,
    commission: StaffCommission
): ThunkAction<void, StaffState, null, StaffAction | LoginAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(saveCommissionsStart());
        const marketplaceId = store.getState().login.auth.marketplaceId!;
        const url = `${staffUrl}/${staffId}?marketplace_id=${marketplaceId}`;
        try {
            const staffRequest = {
                id: staffId,
                marketplace_id: marketplaceId,
                commissions_attributes: {
                    id: commission.id,
                    marketplace_id: marketplaceId,
                    staff_id: staffId,
                    uuid: commission.uuid,
                    rates: JSON.stringify(
                        commission.rates.map(serviceRate => ({
                            service_id: serviceRate.service.id,
                            service_name: serviceRate.service.name,
                            mode: serviceRate.rate.type,
                            value: serviceRate.rate.value,
                            active: serviceRate.active
                        }))
                    ),
                    active: commission.active
                }
            };
            let formData = new FormData();
            const options = {
                indices: false,
                nullsAsUndefineds: false,
                booleansAsIntegers: false,
                allowEmptyArrays: false
            };
            formData = serialize(staffRequest, options, formData, 'staff');
            const response: AxiosResponse<StaffDto> = await ApiClient.patch(
                url,
                formData,
                createMultipartTokenConfig(store.getState().login.auth.token!)
            );
            const savedStaff = convertStaff(response.data);
            dispatch(saveCommissionsSuccess(savedStaff));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                alertErrorHandler(apiError, dispatch, `Error saving commissions.`);
            }
        }
    };
};

export const saveDefaultScheduleStaffThunk = (
    defaultSchedule: Week,
    staffId: number
): ThunkAction<void, StaffState, null, StaffAction | LoginAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(saveDefaultScheduleStart());

        const marketplaceId = store.getState().login.auth.marketplaceId!;
        const saveUrl = `${staffUrl}/${staffId}?marketplace_id=${marketplaceId}`;

        try {
            let member = store.getState().staff.member;

            if (!member) {
                member = store.getState().staff.staff.find(s => s.id === staffId);
            }

            if (!member) {
                const getUrl = `${staffUrl}/${staffId}?marketplace_id=${marketplaceId}`;
                const response: AxiosResponse<StaffDto> = await ApiClient.get(
                    getUrl,
                    createTokenConfig(store.getState().login.auth.token!)
                );
                member = convertStaff(response.data);
            }

            const scheduleWorkId = member.schedule.workId;
            const scheduleCalendarId = member.schedule.calendarId;

            const newCustomDayIds = removeCustomsEqualsAsDefault(member.schedule.customDays, defaultSchedule).map(
                customDay => customDay.id
            );
            const customSchedulesToBeRemoved = member.schedule.customDays.filter(
                customDay => !newCustomDayIds.includes(customDay.id)
            );

            const saveRequest = {
                id: staffId,
                marketplace_id: marketplaceId,
                works_attributes: [
                    {
                        id: scheduleWorkId,
                        marketplace_id: marketplaceId,
                        staff_id: staffId,
                        calendar_attributes: {
                            id: scheduleCalendarId,
                            marketplace_id: marketplaceId,
                            range: JSON.stringify(defaultSchedule)
                        },
                        customize_schedules_attributes: customSchedulesToBeRemoved.map(customDay => ({
                            id: customDay.id,
                            _destroy: true
                        }))
                    }
                ]
            };

            let formData = new FormData();

            const options = {
                indices: false,
                nullsAsUndefineds: false,
                booleansAsIntegers: false,
                allowEmptyArrays: false
            };

            formData = serialize(saveRequest, options, formData, 'staff');

            const response: AxiosResponse<StaffDto> = await ApiClient.patch(
                saveUrl,
                formData,
                createMultipartTokenConfig(store.getState().login.auth.token!)
            );
            const savedStaff = convertStaff(response.data);
            dispatch(saveDefaultScheduleSuccess(savedStaff));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                alertErrorHandler(apiError, dispatch, `Error saving staff id ${staffId}.`);
            }
        }
        dispatch(fetchStaffThunk());
    };
};

export const deleteStaffThunk = (
    staff: Staff
): ThunkAction<void, StaffState, null, StaffAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(deleteStart(staff.id!));

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const url = `${staffUrl}/${staff.id}?marketplace_id=${marketplaceId}`;

        try {
            const body = {
                staff: {
                    id: staff.id,
                    deleted: true
                }
            };
            await ApiClient.patch(url, body, createTokenConfig(store.getState().login.auth.token!));
            dispatch(deleteSuccess(staff.id!));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                const name = `${staff.person.firstName} ${staff.person.lastName}`;
                alertErrorHandler(apiError, dispatch, `Error deleting staff member ${name}.`);
            }
        }
        dispatch(fetchStaffThunk());
    };
};

export const activateDeactivateStaffThunk = (
    staff: Staff
): ThunkAction<void, StaffState, null, StaffAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(activateDeactivateStart(staff.active));

        const marketplaceId = store.getState().login.auth.marketplaceId!;
        const id = staff.id;
        const work = staff.worksAt.find(w => w.marketplaceId === marketplaceId)!;
        const apiClientMethod = ApiClient.patch;
        const url = `${staffUrl}/${id}?marketplace_id=${marketplaceId}`;
        const body = {
            staff: {
                id,
                works_attributes: [{ id: work.id, staff_id: staff.id, active: work.active }]
            }
        };
        try {
            await apiClientMethod(url, body, createTokenConfig(store.getState().login.auth.token!));
            dispatch(activateDeactivateSuccess(staff.active));
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                const action = staff.active ? 'activating' : 'deactivating';
                const name = `${staff.person.firstName} ${staff.person.lastName}`;
                dispatch(showError(`Error ${action} staff member ${name}.`));
            }
        }
        dispatch(fetchStaffThunk());
    };
};

export const saveStaffSlotsThunk = (
    staff: Staff
): ThunkAction<void, RootState, null, StaffAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(saveStart());

        const marketplaceId = store.getState().login.auth.marketplaceId!;
        const url = `${staffUrl}/${staff.id}?marketplace_id=${marketplaceId}`;

        try {
            const staffRequest = {
                id: staff.id,
                marketplace_id: marketplaceId,
                works_attributes: convertWorksToDto(staff, marketplaceId, undefined, true)
            };

            let formData = new FormData();

            const options = {
                indices: false,
                nullsAsUndefineds: false,
                booleansAsIntegers: false,
                allowEmptyArrays: false
            };

            formData = serialize(staffRequest, options, formData, 'staff');

            const response: AxiosResponse<StaffDto | { staff: StaffDto; warnings: Array<Array<string>> }> =
                await ApiClient.patch(url, formData, createMultipartTokenConfig(store.getState().login.auth.token!));

            if ('warnings' in response.data) {
                const warnings = response.data.warnings[0];

                dispatch(error());
                dispatch(saveSuccess(convertStaff(response.data.staff)));
                dispatch(showSuccessThunk('Slots updated with warnings'));
                setTimeout(() => dispatch(setStaffWarnings(warnings)), 100);
            }

            if (!('warnings' in response.data)) {
                dispatch(saveSuccess(convertStaff(response.data)));
                dispatch(showSuccessThunk('Slots updated successfully'));
            }
        } catch (apiError) {
            if (!isCancelled(apiError)) {
                dispatch(error());
                alertErrorHandler(apiError, dispatch, `Error saving slots.`);
            }
        }
    };
};

const convertWorksToDto = (staff: Staff, marketplaceId: number, member?: Staff, skipCustomizeSchedules = false) => {
    return staff.worksAt
        .filter(w => w.id === undefined || w.marketplaceId === marketplaceId)
        .map(w => ({
            id: w.id,
            marketplace_id: w.marketplaceId,
            staff_id: staff.id,
            role_id: w.roleId,
            access_level_id: w.accessLevelId,
            calendar_attributes: staff.schedule?.default
                ? convertScheduleToDto(staff.schedule, staff.slots, marketplaceId)
                : {
                      id: undefined,
                      marketplace_id: marketplaceId,
                      range: JSON.stringify({
                          monday: null,
                          tuesday: null,
                          wednesday: null,
                          thursday: null,
                          friday: null,
                          saturday: null,
                          sunday: null
                      })
                  },
            ...(!skipCustomizeSchedules && {
                customize_schedules_attributes: [
                    ...staff.schedule.customDays.map(cd => ({
                        id: cd.id,
                        work_id: staff.worksAt.find(w => w.marketplaceId === marketplaceId)?.id,
                        uuid: cd.uuid,
                        from: cd.from.toDate(),
                        to: cd.to.toDate(),
                        on: cd.on
                    })),
                    ...(member?.schedule.customDays || [])
                        .filter(cd => !staff.schedule.customDays.some(staffCustomDay => cd.id === staffCustomDay.id))
                        .map(cd => ({ id: cd.id, _destroy: true }))
                ]
            })
        }));
};

const convertScheduleToDto = (schedule: StaffSchedule, slots: StaffSlots, marketplaceId: number) => {
    return {
        id: schedule.calendarId,
        marketplace_id: marketplaceId,
        range: JSON.stringify(schedule.default),
        ...(slots.default && convertDefaultSlotsToDto(slots.default)),
        ...(slots.customDays && {
            specific_date_slots:
                slots.customDays.length > 0
                    ? JSON.stringify(
                          slots.customDays.map(slot => ({
                              time: slot.time,
                              on: slot.on === undefined ? true : slot.on,
                              date: slot.date.format('YYYY-MM-DD'),
                              pet_count: slot.petsCount
                          }))
                      )
                    : null
        })
    };
};

const convertDefaultSlotsToDto = (slots: Array<StaffDefaultSlot>) => {
    return {
        slots: JSON.stringify(
            slots.reduce((weekSlots, slot) => {
                if (!weekSlots[slot.weekDay]) {
                    weekSlots[slot.weekDay] = [];
                }

                weekSlots[slot.weekDay].push({
                    time: slot.time,
                    pet_count: slot.petsCount
                });

                return weekSlots;
            }, {} as Record<string, Array<DefaultSlotDto>>)
        )
    };
};

export const resendInvitationThunk = (
    staffId: number
): ThunkAction<void, StaffState, null, StaffAction | NotificationsAction> => {
    return async dispatch => {
        dispatch(resendInvitationStart());

        const marketplaceId = store.getState().login.auth.marketplaceId;
        const token = store.getState().login.auth.token; // Get the token directly here for clarity
        const url = `/staff/${staffId}/resend_invite?marketplace_id=${marketplaceId}`;

        if (token) {
            try {
                await ApiClient.post(url, null, createTokenConfig(token));
                dispatch(resendInvitationSuccess());
                dispatch(showSuccess('Invitation resent successfully.'));
            } catch (apiError) {
                if (!isCancelled(apiError)) {
                    dispatch(resendInvitationError());
                    dispatch(showError('Error resending the invitation.'));
                }
            }
        }
    };
};

const resendInvitationStart = (): StaffAction => ({
    type: STAFF_RESEND_INVITATION_START_ACTION_TYPE
});

const resendInvitationSuccess = (): StaffAction => ({
    type: STAFF_RESEND_INVITATION_SUCCESS_ACTION_TYPE
});

const resendInvitationError = (): StaffAction => ({
    type: STAFF_RESEND_INVITATION_ERROR_ACTION_TYPE
});

export const reset = (): StaffAction => {
    return {
        type: STAFF_RESET_ACTION_TYPE
    };
};

const fetchStart = (): StaffAction => {
    return {
        type: STAFF_FETCH_START_ACTION_TYPE
    };
};

const fetchSucces = (staffDto: Array<StaffDto>): StaffAction => {
    return {
        type: STAFF_FETCH_SUCCESS_ACTION_TYPE,
        payload: {
            staff: staffDto.map(staff => convertStaff(staff))
        }
    };
};

const getStart = (): StaffAction => {
    return {
        type: STAFF_GET_START_ACTION_TYPE
    };
};

const getSucces = (staffDto: StaffDto): StaffAction => {
    return {
        type: STAFF_GET_SUCCESS_ACTION_TYPE,
        payload: {
            member: convertStaff(staffDto)
        }
    };
};

const getByEmailStart = (): StaffAction => {
    return {
        type: STAFF_GET_BY_EMAIL_START_ACTION_TYPE
    };
};

const getByEmailSucces = (staffDto: StaffDto): StaffAction => {
    return {
        type: STAFF_GET_BY_EMAIL_SUCCESS_ACTION_TYPE,
        payload: {
            member: convertStaff(staffDto)
        }
    };
};

const getByEmailNotFound = (): StaffAction => {
    return {
        type: STAFF_GET_BY_EMAIL_NOT_FOUND_ACTION_TYPE
    };
};

const saveStart = (): StaffAction => {
    return {
        type: STAFF_SAVE_START_ACTION_TYPE
    };
};

const saveSuccess = (staff: Staff): StaffAction => {
    return {
        type: STAFF_SAVE_SUCCESS_ACTION_TYPE,
        payload: {
            savedStaff: staff
        }
    };
};

const saveCommissionsStart = (): StaffAction => {
    return {
        type: STAFF_SAVE_COMMISSIONS_START_ACTION_TYPE
    };
};
const saveCommissionsSuccess = (staff: Staff): StaffAction => {
    return {
        type: STAFF_SAVE_COMMISSIONS_SUCCESS_ACTION_TYPE,
        payload: {
            savedStaff: staff
        }
    };
};

const saveDefaultScheduleStart = (): StaffAction => {
    return {
        type: STAFF_SAVE_DEFAULT_SCHEDULE_START_ACTION_TYPE
    };
};

const saveDefaultScheduleSuccess = (staff: Staff): StaffAction => {
    return {
        type: STAFF_SAVE_DEFAULT_SCHEDULE_SUCCESS_ACTION_TYPE,
        payload: {
            savedStaff: staff
        }
    };
};

const deleteStart = (deletedStaffId: number): StaffAction => {
    return {
        type: STAFF_DELETE_START_ACTION_TYPE,
        payload: {
            deletedStaffId
        }
    };
};

const deleteSuccess = (deletedStaffId: number): StaffAction => {
    return {
        type: STAFF_DELETE_SUCCESS_ACTION_TYPE,
        payload: {
            deletedStaffId
        }
    };
};

const activateDeactivateStart = (activate: boolean): StaffAction => {
    return {
        type: STAFF_ACTIVATE_DEACTIVATE_START_ACTION_TYPE,
        payload: {
            activate
        }
    };
};

const activateDeactivateSuccess = (activate: boolean): StaffAction => {
    return {
        type: STAFF_ACTIVATE_DEACTIVATE_SUCCESS_ACTION_TYPE,
        payload: {
            activate
        }
    };
};

const setStaffWarnings = (warnings: Array<string>): StaffAction => {
    return {
        type: STAFF_SET_WARNINGS_ACTION_TYPE,
        payload: {
            warnings
        }
    };
};

export const clearStaffWarnings = (): StaffAction => {
    return {
        type: STAFF_CLEAR_WARNINGS_ACTION_TYPE
    };
};

const error = (): StaffAction => {
    return {
        type: STAFF_ERROR_ACTION_TYPE
    };
};
