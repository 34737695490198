import { createEmptyWeek, Option, Week } from '@spike/model';
import { createEmptySchedule } from 'components/StaffSchedule/utils/StaffScheduleUtils';
import { Moment } from 'moment';
import store from 'store';
import { v4 as uuid } from 'uuid';
export interface Staff {
    id?: number;
    uuid: string;
    person: StaffPerson;
    businessAreas: Array<Option<string>>;
    businessArea?: Option<string>;
    role?: Option<number>;
    emergencyContact: StaffEmergencyContact;
    accessLevel?: Option<number>;
    services: Array<StaffService>;
    notifications: StaffNotifications;
    schedule: StaffSchedule;
    admin?: boolean;
    active: boolean;
    deleted: boolean;
    destroyable: boolean;
    worksAt: Array<StaffWorkAt>;
    createdAt?: Moment;
    last_login: Moment | null;
    commissions: Array<StaffCommission>;
    slots: StaffSlots;
}

export interface StaffCommission {
    id?: number;
    uuid: string;
    active: boolean;
    createdAt: Moment;
    updatedAt: Moment;
    rates: Array<StaffServiceRate>;
}

export interface StaffServiceRate {
    service: ServiceRate;
    active: boolean;
    rate: Rate;
}
export interface ServiceRate {
    id: number;
    name: string;
}
export interface Rate {
    type: 'amount' | 'percentage';
    value: number;
}

export interface StaffPerson {
    firstName: string;
    lastName: string;
    avatar: string;
    birthdate?: Moment;
    phoneNumber: string;
    email: string;
    address: string;
    fileToUpload?: File;
}

export interface StaffNotifications {
    phone: boolean;
    email: boolean;
}

export interface StaffEmergencyContact {
    id?: number;
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    relationship?: Option<number>;
}

export interface StaffService {
    id?: number;
    performId?: number;
    uuid: string;
    name: string;
}

export interface StaffSchedule {
    calendarId?: number;
    workId?: number;
    default: Week;
    customDays: Array<StaffScheduleCustomDay>;
}

export interface StaffScheduleCustomDay {
    id?: number;
    uuid: string;
    from: Moment;
    to: Moment;
    on: boolean;
}

export interface StaffWorkAt {
    id?: number;
    marketplaceId: number;
    staffId?: number;
    roleId?: number;
    accessLevelId?: number;
    active: boolean;
}

export interface StaffBaseSlot {
    uuid: string;
    time: string;
    petsCount: number;
}

export interface StaffDefaultSlot extends StaffBaseSlot {
    weekDay: string;
}

export interface StaffCustomDaySlot extends StaffBaseSlot {
    on: boolean;
    date: Moment;
}

export type StaffSlot = StaffDefaultSlot | StaffCustomDaySlot;

export interface StaffSlots {
    default: Array<StaffDefaultSlot>;
    customDays: Array<StaffCustomDaySlot>;
}

export interface StaffScheduleByDate {
    from: Moment;
    to: Moment;
    isStaffAvailable: boolean;
    isBusinessClosed: boolean;
}

export const createEmptyStaff = (roleId: number, accessLevelId: number): Staff => {
    const masterData = store.getState().masterData.data;

    return {
        uuid: uuid(),
        person: {
            firstName: '',
            lastName: '',
            avatar: '',
            address: '',
            phoneNumber: '',
            email: ''
        },
        businessAreas: [],
        role: undefined,
        accessLevel: masterData.accessLevel.find(accessLevel => accessLevel.id === 2)!,
        emergencyContact: {
            id: undefined,
            firstName: '',
            lastName: '',
            relationship: undefined,
            email: '',
            phoneNumber: ''
        },
        services: [],
        notifications: {
            phone: false,
            email: false
        },
        schedule: createEmptySchedule(),
        active: true,
        destroyable: true,
        worksAt: [],
        deleted: false,
        last_login: null,
        commissions: [],
        slots: {
            default: [] as Array<StaffDefaultSlot>,
            customDays: [] as Array<StaffCustomDaySlot>
        }
    };
};

export enum SortFields {
    FIRST_NAME = 'firstName',
    SCHEDULE = 'schedule',
    ROLE = 'role',
    EMAIL = 'email',
    PHONE_NUMBER = 'phoneNumber',
    STATUS = 'status',
    ACCESS_LEVEL = 'accessLevel'
}

export enum SortOrder {
    ASCENDANT = 'ascendant',
    DESCENDANT = 'descendant',
    CREATION_DATE = 'creationDate'
}

export default Staff;
