import { faPlus } from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Period } from '@spike/model';
import { showSuccessThunk } from '@spike/notifications-action';
import { clearStaffWarnings, saveStaffSlotsThunk } from 'actions/staff/StaffActions';
import clsx from 'clsx';
import { AlertMessage2 } from 'components/AlertMessage2';
import {
    MultiSlotDatePicker,
    MultiSlotDatePickerOption
} from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/DatePicker';
import { MultiSlotDrawerBody } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/MultiSlotDrawerBody';
import { MultiSlotDrawerFooter } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/MultiSlotDrawerFooter';
import { MultiSlotDrawerHeader } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/MultiSlotDrawerHeader';
import { SlotField } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/SlotField';
import { StaffCard } from 'components/Marketplace/BusinessSettings/ScheduleType/MultiSlotDrawer/StaffCard';
import {
    generateHourOptions,
    getDefaultSlotDate,
    getStaffScheduleByDate,
    getStaffSlots,
    isCustomSlot,
    isDefaultSlot,
    isDefaultSlotDisabled
} from 'components/StaffSchedule/utils/StaffScheduleUtils';
import { ConfirmDialog } from 'components/UI';
import Button from 'components/UI/V2/Button/Button';
import { useMarketplace, useTimeZone } from 'hooks';
import Staff, { StaffCustomDaySlot, StaffSlot } from 'model/Staff';
import moment, { Moment } from 'moment-timezone';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { StaffStatus } from 'reducers/staff/StaffState';
import { RootState } from 'store';
import { v4 as uuid } from 'uuid';

export interface AddSlotProps {
    period: Period;
    selectedStaff?: Staff;
    defaultDate?: Moment;
    onClose: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        header: {
            top: 0,
            zIndex: 10,
            width: '100%',
            padding: '0px 16px',
            position: 'sticky',
            backgroundColor: '#ffffff',

            [theme.breakpoints.up('md')]: {
                padding: '0px 30px'
            }
        },
        slotsContainer: {
            width: '100%'
        },
        addSlotButton: {
            gap: 6,
            border: 0,
            fontSize: 16,
            fontWeight: 500,
            fontFamily: 'Poppins',
            cursor: 'pointer',
            appearance: 'none',
            background: 'none',
            display: 'flex',
            alignItems: 'center'
        },
        alertMessage: {
            'margin': 0,

            '& p': {
                fontSize: 16,
                lineHeight: 1.4,
                paddingRight: 10
            },
            '& button': {
                marginTop: 16
            }
        },
        pastDateMessage: {
            '& .MuiGrid-container': {
                [theme.breakpoints.up('sm')]: {
                    alignItems: 'center'
                }
            }
        },
        confirmDialog: {
            '& .MuiDialog-paperWidthSm': {
                maxWidth: 455
            },
            '& .MuiDialogActions-root': {
                '& button': {
                    margin: 0,
                    height: 47,
                    width: '100%'
                }
            }
        },
        confirmDialogText: {
            'maxWidth': 320,
            'margin': '0 auto',

            '& b': {
                fontWeight: 600
            }
        }
    })
);

export const AddSlot: React.FC<AddSlotProps> = props => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const timeZone = useTimeZone();
    const marketplace = useMarketplace();

    const today = moment().tz(timeZone);

    const staffState = useSelector((state: RootState) => state.staff);

    const [selectedDate, setSelectedDate] = React.useState<Moment>();
    const [originalSlots, setOriginalSlots] = React.useState<Array<StaffSlot>>([]);
    const [modifiedSlots, setModifiedSlots] = React.useState<Array<StaffSlot>>([]);
    const [showDeleteAllDialog, setShowDeleteAllDialog] = React.useState(false);

    const isBeforeToday = React.useMemo(() => {
        return selectedDate?.isBefore(today, 'day');
    }, [today, selectedDate]);

    const isSlotModified = (originalSlots: Array<StaffSlot>, slot: StaffSlot) => {
        return originalSlots.some(originalSlot => originalSlot.uuid === slot.uuid && originalSlot.time !== slot.time);
    };

    const getSortedDaySlots = () => {
        const weekDay = selectedDate?.format('dddd').toLowerCase();

        const defaultSlots = modifiedSlots.filter(
            slot =>
                isDefaultSlot(slot) &&
                slot.weekDay === weekDay &&
                !isDefaultSlotDisabled(modifiedSlots, slot, getDefaultSlotDate(props.period.from, slot))
        );

        const customDaySlots = modifiedSlots.filter(slot => {
            if (isCustomSlot(slot)) {
                return slot.date.isSame(selectedDate, 'day') && slot.on;
            }
        });

        const originalDaySlots = [...defaultSlots, ...customDaySlots]
            .filter(slot => originalSlots.some(originalSlot => originalSlot.uuid === slot.uuid))
            .sort((a, b) => a.time.localeCompare(b.time));

        const newSlots = [...defaultSlots, ...customDaySlots].filter(
            slot => !originalSlots.some(originalSlot => originalSlot.uuid === slot.uuid)
        );

        return [...originalDaySlots, ...newSlots];
    };

    const daySlots = React.useMemo(getSortedDaySlots, [modifiedSlots, selectedDate]);

    const selectedDateHours = React.useMemo(() => {
        return daySlots.map(slot => slot.time);
    }, [daySlots]);

    const daySchedule = React.useMemo(() => {
        if (!selectedDate || !props.selectedStaff) return null;

        return getStaffScheduleByDate(marketplace, props.selectedStaff?.schedule, selectedDate.clone(), timeZone);
    }, [selectedDate, props.selectedStaff]);

    const hourOptions = React.useMemo(() => {
        if (!selectedDate || !daySchedule) return [];

        return generateHourOptions(daySchedule.from, daySchedule.to);
    }, [selectedDate, daySchedule]);

    const dateOptions = React.useMemo(() => {
        const dates: Array<MultiSlotDatePickerOption<Moment>> = [];
        const current = props.period.from.clone();

        while (current.isSameOrBefore(props.period.to, 'day')) {
            dates.push({
                label: current.format('ddd, DD'),
                value: current.clone()
            });
            current.add(1, 'day');
        }

        return dates;
    }, [props.period]);

    const addSlotHandler = () => {
        if (!selectedDate) return;

        let index = 0;
        let selectedTime: string;

        while (index < hourOptions.length) {
            const value = hourOptions[index].value;
            if (!selectedDateHours.includes(value)) {
                selectedTime = value;
                break;
            }
            index++;
        }

        setModifiedSlots(prevSlots => [
            ...prevSlots,
            {
                on: true,
                uuid: uuid(),
                petsCount: 1,
                date: selectedDate,
                time: selectedTime
            } as StaffCustomDaySlot
        ]);
    };

    const changeSlotHandler = (slot: StaffSlot, time?: string, petsCount?: number) => {
        if (!time || !petsCount) return;

        if (isDefaultSlot(slot) && slot.time === time) {
            return setModifiedSlots(prevSlots => [
                ...prevSlots,
                {
                    on: true,
                    date: selectedDate,
                    petsCount: petsCount || slot.petsCount,
                    time: time || slot.time
                } as StaffCustomDaySlot
            ]);
        }

        if (isDefaultSlot(slot) && slot.time !== time) {
            return setModifiedSlots(prevSlots => [
                ...prevSlots,
                // Specific date slot to disable default slot that time has changed
                {
                    on: false,
                    date: selectedDate,
                    petsCount: petsCount || slot.petsCount,
                    time: slot.time
                } as StaffCustomDaySlot,
                // Specific date slot to enable new time
                {
                    on: true,
                    date: selectedDate,
                    petsCount: petsCount || slot.petsCount,
                    time: time
                } as StaffCustomDaySlot
            ]);
        }

        setModifiedSlots(prevSlots =>
            prevSlots.map(prevSlot => (prevSlot === slot ? { ...prevSlot, time, petsCount } : prevSlot))
        );
    };

    const deleteSlotHandler = (slot: StaffSlot) => {
        if (isDefaultSlot(slot)) {
            return setModifiedSlots(prevSlots => [
                ...prevSlots,
                {
                    on: false,
                    petsCount: 0,
                    date: getDefaultSlotDate(props.period.from, slot),
                    time: slot.time
                } as StaffCustomDaySlot
            ]);
        }

        setModifiedSlots(prevSlots => {
            const newSlots = prevSlots.filter(prevSlot => prevSlot !== slot);

            /*
             * If the slot is a custom slot, we need to disable the
             * default slot that has the same time and week day
             */
            if (isCustomSlot(slot)) {
                const defaultSlot = prevSlots.find(
                    prevSlot =>
                        isDefaultSlot(prevSlot) &&
                        prevSlot.time === slot.time &&
                        prevSlot.weekDay === slot.date.format('dddd').toLowerCase()
                );

                if (defaultSlot) {
                    newSlots.push({
                        on: false,
                        petsCount: 0,
                        date: slot.date,
                        time: slot.time
                    } as StaffCustomDaySlot);
                }
            }

            return newSlots;
        });
    };

    const saveSlotsHandler = () => {
        if (!props.selectedStaff) return;

        dispatch(
            saveStaffSlotsThunk({
                ...props.selectedStaff,
                slots: {
                    ...props.selectedStaff.slots,
                    customDays: modifiedSlots.filter(slot => isCustomSlot(slot)) as Array<StaffCustomDaySlot>
                }
            })
        );
    };

    const deleteDaySlotsHandler = () => {
        if (!props.selectedStaff) return;

        setShowDeleteAllDialog(true);
    };

    const confirmDeleteDaySlotsHandler = () => {
        if (!props.selectedStaff) return;

        const selectedWeekDay = selectedDate?.format('dddd').toLowerCase();
        const defaultWeekDaySlots = originalSlots
            .filter(isDefaultSlot)
            .filter(slot => slot.weekDay === selectedWeekDay);

        const deletedDefaultSlots: Array<StaffCustomDaySlot> = defaultWeekDaySlots.map(slot => ({
            on: false,
            uuid: uuid(),
            petsCount: 0,
            time: slot.time,
            date: getDefaultSlotDate(props.period.from, slot)
        }));

        const customSlots = originalSlots.filter(slot => isCustomSlot(slot)) as Array<StaffCustomDaySlot>;

        dispatch(
            saveStaffSlotsThunk({
                ...props.selectedStaff,
                slots: {
                    ...props.selectedStaff.slots,
                    customDays: customSlots
                        .filter(slot => !slot.date.isSame(selectedDate, 'day'))
                        .concat(deletedDefaultSlots)
                }
            })
        );
    };

    /*
     * Check if the modified slots are different from the original slots
     */
    const isModified = React.useMemo(() => {
        return JSON.stringify(originalSlots) !== JSON.stringify(modifiedSlots);
    }, [originalSlots, modifiedSlots]);

    /*
     * Assign slots from staff, and assign the slots to be modified
     */
    React.useEffect(() => {
        if (!props.selectedStaff) return;

        const staffSlots = getStaffSlots(props.selectedStaff?.slots);

        setOriginalSlots(staffSlots);
        setModifiedSlots(staffSlots);
    }, [props.selectedStaff]);

    /*
     * Set the selected date, if no default date is provided, use the period's start date
     */
    React.useEffect(() => {
        const defaultSelectedDate = props.defaultDate || props.period.from;

        setSelectedDate(defaultSelectedDate.clone());
    }, []);

    React.useEffect(() => {
        if (staffState.status === StaffStatus.SaveSuccess) {
            if (showDeleteAllDialog) {
                setShowDeleteAllDialog(false);
                dispatch(showSuccessThunk('Slots deleted successfully'));
                return;
            }
        }
    }, [staffState.status]);

    React.useEffect(() => {
        dispatch(clearStaffWarnings());
    }, [modifiedSlots, selectedDate]);

    return (
        <>
            <MultiSlotDrawerHeader
                title="Add Slots"
                label="Select a day"
                onClose={props.onClose}
            >
                <MultiSlotDatePicker<Moment>
                    options={dateOptions}
                    selectedOption={selectedDate}
                    onDateChange={setSelectedDate}
                    isSelected={option => option.value.isSame(selectedDate, 'day')}
                />
            </MultiSlotDrawerHeader>

            <MultiSlotDrawerBody>
                {props.selectedStaff && (
                    <StaffCard
                        staff={props.selectedStaff}
                        selectedDate={selectedDate}
                    />
                )}

                {isBeforeToday && !daySchedule?.isBusinessClosed && daySchedule?.isStaffAvailable && (
                    <AlertMessage2
                        icon={faCircleInfo}
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                        className={clsx(classes.alertMessage, classes.pastDateMessage)}
                    >
                        <Typography>You can&apos;t make changes to slots from past dates.</Typography>
                    </AlertMessage2>
                )}

                {daySchedule?.isBusinessClosed ? (
                    <AlertMessage2
                        icon={faCircleInfo}
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                        className={classes.alertMessage}
                    >
                        <Typography>
                            Your business hours are set to closed for this day. Update your business hours to add time
                            slots.
                        </Typography>

                        <Link
                            to="/business_settings/info/hours"
                            id="add-slot-alert-setup-hours-button"
                        >
                            <Button
                                variant="black"
                                label="Setup Business Hours"
                            />
                        </Link>
                    </AlertMessage2>
                ) : !daySchedule?.isStaffAvailable ? (
                    <AlertMessage2
                        icon={faCircleInfo}
                        bgColor="#F8F5F1"
                        bdColor="#BCB8AE"
                        className={classes.alertMessage}
                    >
                        <Typography>
                            This staff member is not scheduled to work on this day. Update their schedule to add time
                            slots.
                        </Typography>

                        <Link
                            to={`/staff#staffId=${props.selectedStaff?.id}`}
                            id="add-slot-alert-edit-schedule-button"
                        >
                            <Button
                                variant="black"
                                label="Edit Schedule"
                            />
                        </Link>
                    </AlertMessage2>
                ) : (
                    <>
                        {staffState.warnings.map((warning, i) => (
                            <AlertMessage2
                                key={i}
                                icon={faCircleInfo}
                                bgColor="#F8F5F1"
                                bdColor="#BCB8AE"
                                className={clsx(classes.alertMessage, classes.pastDateMessage)}
                            >
                                <Typography>{warning}</Typography>
                            </AlertMessage2>
                        ))}

                        {daySlots.length > 0 && (
                            <div className={classes.slotsContainer}>
                                <SlotField showLabel />

                                {daySlots.map((slot, index) => (
                                    <SlotField
                                        key={index}
                                        hours={hourOptions}
                                        time={slot.time}
                                        petsCount={slot.petsCount}
                                        readOnly={isBeforeToday}
                                        selectedHours={selectedDateHours}
                                        onDelete={() => deleteSlotHandler(slot)}
                                        onChange={(time, petsCount) => changeSlotHandler(slot, time, petsCount)}
                                    />
                                ))}
                            </div>
                        )}

                        {!isBeforeToday && (
                            <button
                                type="button"
                                className={classes.addSlotButton}
                                id="add-slot-button"
                                onClick={addSlotHandler}
                                disabled={selectedDateHours.length >= hourOptions.length}
                            >
                                <FontAwesomeIcon icon={faPlus} />
                                Add Slot
                            </button>
                        )}
                    </>
                )}
            </MultiSlotDrawerBody>

            {!isBeforeToday && !daySchedule?.isBusinessClosed && daySchedule?.isStaffAvailable && (
                <MultiSlotDrawerFooter>
                    <Button
                        noColorOnDisabled
                        variant="danger"
                        label="Delete All"
                        id="add-slot-delete-all-button"
                        disabled={daySlots.length === 0}
                        onClick={deleteDaySlotsHandler}
                    />
                    <Button
                        label="Save"
                        variant="green"
                        noColorOnDisabled
                        id="add-slot-save-button"
                        disabled={!isModified}
                        isLoading={staffState.loading}
                        onClick={saveSlotsHandler}
                    />
                </MultiSlotDrawerFooter>
            )}

            {showDeleteAllDialog && (
                <ConfirmDialog
                    open={true}
                    processing={staffState.loading}
                    className={classes.confirmDialog}
                    title={<Typography>Delete Multi Slots</Typography>}
                    question={
                        <Typography className={classes.confirmDialogText}>
                            Are you sure you want to delete all multi-slots for <b>{selectedDate?.format('ddd, DD')}</b>
                            ?
                        </Typography>
                    }
                    cancelButtonLabel="No"
                    confirmButtonLabel="Yes"
                    onCancel={() => setShowDeleteAllDialog(false)}
                    onConfirm={confirmDeleteDaySlotsHandler}
                />
            )}
        </>
    );
};
