import { FunctionComponent } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { faUsers } from '@fortawesome/pro-light-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Header } from 'components/UI';
import SelectionBar, { SelectionBarOption } from './SelectionBar';
import { Member } from 'components/UI/StaffFilter/StaffFilter';
import { CalendarFilter } from 'components/UI';
import { Period } from '@spike/model';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Filter from 'components/UI/Filter';
import { Option } from '@spike/model';
import Button from 'components/UI/V2/Button/Button';
import BookingsExportCSV from '../BookingsExportCSV';
import { BookingWrapper } from '@spike/booking-model';

export interface BookingsHeaderProps {
    period: Period;
    views: Array<SelectionBarOption>;
    selectedViewId: string;
    onSelectView: (id: string) => void;
    onSearch: (text: string) => void;
    onAddAppointment?: () => void;
    onChangePeriod: (period: Period) => void;
    onSelectStaff: (staffIds: Array<number | undefined>, allSelected: boolean) => void;
    count?: number;
    title: boolean;
    fromClient?: boolean;
    staff: Array<Member>;
    hideAllTime?: boolean;
    optionSelected?: string;
    showExportButton?: boolean;
    filteredAndSorted: Array<BookingWrapper>;
    mobileBreakpoint?: number;
}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            background: 'white',
            borderRadius: '18px 18px 0 0' /* for content appointment inside client detail */
        },
        secondLineHeader: {
            alignItems: 'center',
            display: 'flex',
            width: '100%',
            [theme.breakpoints.down(768)]: {
                marginTop: '20px',
                paddingRight: '20px',
                paddingLeft: '20px'
            },
            [theme.breakpoints.between(768, 'sm')]: {
                marginTop: '11px',
                paddingRight: '40px',
                paddingLeft: '25px'
            },
            [theme.breakpoints.only('md')]: {
                marginTop: '18px',
                paddingRight: '65px',
                paddingLeft: '25px'
            },
            [theme.breakpoints.only('lg')]: {
                marginTop: '24px',
                paddingRight: '87px',
                paddingLeft: '25px'
            },
            [theme.breakpoints.only('xl')]: {
                marginTop: '35px',
                paddingRight: '132px',
                paddingLeft: '50px'
            }
        },
        secondLineHeaderClient: {
            marginTop: '0'
        },
        buttonsContainer: {
            display: 'flex',
            flex: '1 1 auto',
            justifyContent: 'flex-end',
            alignItems: 'center'
        },
        button: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: '9px'
            },
            [theme.breakpoints.only('md')]: {
                marginLeft: '14px'
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: '18px'
            },
            [theme.breakpoints.only('xl')]: {
                marginLeft: '28px'
            },

            '& .MuiButton-startIcon': {
                marginRight: 6
            }
        },
        exportContainer: {
            display: 'flex',
            alignItems: 'center',
            marginRight: theme.spacing(2),
            [theme.breakpoints.down('sm')]: {
                marginRight: theme.spacing(1)
            }
        },
        appointmentContainer: {
            'height': '100%',
            'position': 'relative',
            'display': 'flex',
            'alignItems': 'center',
            'justifyContent': 'flex-end',

            [theme.breakpoints.down('sm')]: {
                marginLeft: '9px'
            },
            [theme.breakpoints.only('md')]: {
                marginLeft: '14px'
            },
            [theme.breakpoints.only('lg')]: {
                marginLeft: '18px'
            },
            [theme.breakpoints.only('xl')]: {
                marginLeft: '28px'
            },

            '& .MuiButton-root': {
                '& .MuiButton-startIcon': {
                    'margin': 0,
                    '& > svg': {
                        width: 16,
                        height: 16,
                        marginRight: 8,
                        [theme.breakpoints.down('lg')]: {
                            width: 14,
                            height: 14,
                            marginRight: 4
                        }
                    }
                },
                [theme.breakpoints.down(1025)]: {
                    'height': 35,
                    'padding': '0px 16px',
                    '& .MuiButton-startIcon': {
                        'margin': 0,
                        '& > svg': {
                            width: 16,
                            height: 'auto',
                            marginRight: 4
                        }
                    }
                }
            }
        }
    })
);

export const BookingsHeader: FunctionComponent<BookingsHeaderProps> = props => {
    const classes = useStyles();

    const getStaffFormatted = () => {
        const staffAux: Array<Option<number>> = [];
        props.staff.map(item => {
            const itemStaff: Option<number> = {
                id: item.id!,
                name: item.name,
                description: item.avatar
            };
            staffAux.push(itemStaff);
        });
        return staffAux;
    };

    const selectionBar = (
        <SelectionBar
            id="bookings"
            options={props.views}
            selectedOptionId={props.selectedViewId}
            onSelect={props.onSelectView}
            count={props.count}
            mobileBreakpoint={props.mobileBreakpoint}
        />
    );

    const staffFilter = (
        <Filter
            label="Staff"
            icon={faUsers}
            options={getStaffFormatted()}
            onSelect={props.onSelectStaff}
            className={classes.button}
        />
    );

    const calendar = (
        <CalendarFilter
            period={props.period}
            optionSelected={props.optionSelected || 'Today'}
            fromClient={props.fromClient}
            onChange={props.onChangePeriod}
            hideAllTime={props.hideAllTime || true}
            className={classes.button}
            id="bookings"
            hideUpcoming={true}
        />
    );

    const exportButton = <BookingsExportCSV filteredAndSorted={props.filteredAndSorted} />;

    const theme = useTheme();
    const upMD = useMediaQuery(theme.breakpoints.up(1025));
    const upLG = useMediaQuery(theme.breakpoints.up(1440));

    const addAppointmentButton = () => {
        let label = '';
        upLG && !props.views.some(view => view.id === 'staff_issues') ? (label = 'Add Appointment') : (label = 'Add');

        return (
            <Box className={classes.appointmentContainer}>
                <Button
                    size="medium"
                    label={label}
                    leftIcon={faPlus}
                    id="booking_main_button_add_appointment"
                    onClick={props.onAddAppointment && props.onAddAppointment}
                />
            </Box>
        );
    };

    const headerRightElements = () => {
        return upMD || props.onAddAppointment === undefined ? <></> : addAppointmentButton();
    };

    return (
        <Box className={classes.container}>
            {props.title ? (
                <Header
                    id="bookings"
                    title="Your Bookings"
                    searchPlaceholder="Search by clients or pets"
                    onSearch={props.onSearch}
                    rightElements={headerRightElements()}
                />
            ) : (
                <Header
                    title=""
                    rightElements={headerRightElements()}
                />
            )}
            <Box
                className={clsx(classes.secondLineHeader, {
                    [classes.secondLineHeaderClient]: props.fromClient
                })}
            >
                <Box>{selectionBar}</Box>
                <Box className={classes.buttonsContainer}>
                    {props.showExportButton && exportButton}
                    {staffFilter}
                    {calendar}
                    {props.title && upMD && props.onAddAppointment ? addAppointmentButton() : <></>}
                </Box>
            </Box>
        </Box>
    );
};

export default BookingsHeader;
