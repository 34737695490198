import { Box, makeStyles, Theme, createStyles, Grid, Card } from '@material-ui/core';
import { SmsChat, SmsMessage } from 'model/TwoWaySms';
import { FunctionComponent, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { reduceResolution, wbp } from 'Theme';
import Chat from './Chat';
import ChatIsEmpty from './Chat/ChatIsEmpty';
import SelectionMenu from './SelectionMenu/SelectionMenu';
import { fetchTwoWaySmsCountThunk } from 'actions/twoWaySms/twoWaySmsActions';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            [theme.breakpoints.down(wbp)]: {
                padding: `${reduceResolution(40)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                padding: '40px'
            },
            backgroundColor: '#F5F5F5'
        },
        container: {
            [theme.breakpoints.down(wbp)]: {
                height: `${reduceResolution(779)}px`
            },
            [theme.breakpoints.up(wbp)]: {
                height: '779px'
            }
        },

        sideBarColumn: {
            height: '100%',
            width: '100%'
        },

        chatColumn: {
            height: '100%'
        },

        chatCard: {
            display: 'flex',
            height: '100%',
            alignItems: 'center',
            border: '1px solid #D4D4D4',
            borderRadius: '20px',
            boxShadow: 'none'
        }
    })
);

export const TwoWaySMS: FunctionComponent = () => {
    const [chatActive, setChatActive] = useState(false);
    const [chatSearchActive, setChatSearchActive] = useState(true);
    const [clientSelected, setClientSelected] = useState<number | undefined>();
    const actualConversation = useSelector<RootState, Array<SmsMessage>>(state => state.twoWaySms.conversation ?? []);
    const chats = useSelector<RootState, Array<SmsChat> | undefined>(state => state.twoWaySms.chats) ?? [];

    const dispatch = useDispatch();
    const classes = useStyles();

    const openChatHandler = (active: boolean, client: number, chatSearchAct?: boolean) => {
        dispatch(fetchTwoWaySmsCountThunk());
        setChatActive(active);
        if (client) {
            setClientSelected(client);
        }
        if (chatSearchAct) {
            setChatSearchActive(chatSearchAct);
        } else {
            actualConversation.length === 0 ? setChatSearchActive(false) : setChatSearchActive(true);
        }
    };

    const handleNewChatRequest = () => {
        setChatSearchActive(false);
    };

    const handleGoBack = () => {
        setChatSearchActive(true);
    };

    return (
        <Box className={classes.root}>
            <Grid
                container
                spacing={4}
                className={classes.container}
            >
                <Grid
                    item
                    xs={12}
                    lg={3}
                    className={classes.sideBarColumn}
                >
                    <SelectionMenu
                        clientSelected={clientSelected}
                        onNewChatRequest={() => {
                            setChatSearchActive(false);
                            handleNewChatRequest();
                        }}
                        onGoBack={() => handleGoBack()}
                        onOpenChat={openChatHandler}
                        chatActive={chatActive}
                        onSearchResult={() => setChatSearchActive(false)}
                        chatSearchActive={chatSearchActive}
                        onClearSelectedClient={() => setClientSelected(undefined)}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    lg={9}
                    className={classes.chatColumn}
                >
                    <Card className={classes.chatCard}>
                        {chats.length === 0 && clientSelected === undefined ? (
                            <ChatIsEmpty />
                        ) : (
                            <Chat clientSelected={clientSelected} />
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default TwoWaySMS;
